import React from "react"

export default function Logo(){
  return (
<svg className="fill-current" xmlns="http://www.w3.org/2000/svg" id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 778.9 356.7" xmlSpace="preserve">
  <defs/>
  <path d="M412.6 232c.9 4.2 1.8 4.8 3.9 4.8 2 0 2.8-.6 3.7-4.8l27.4-119.7c.9-3.7 1.9-5.3 5.9-5.3h7.2c3.9 0 4.8 2.4 4.1 5l-32 133.4c-1.5 6.5-6.1 7.4-10.9 7.4h-11.6c-4.8 0-9.4-.8-10.9-7.4l-32-133.4c-.6-2.6.2-5 4.1-5h7.6c4.1 0 5.2 1.6 6.1 5.3L412.6 232zM523.9 248.1c0 3.3-1.5 4.8-4.8 4.8h-6.8c-3.3 0-4.8-1.5-4.8-4.8v-136c0-3.3 1.5-5 4.8-5h6.8c3.3 0 4.8 1.8 4.8 5v136zM646.5 107c3.3 0 5 1.6 5 4.8v5.6c0 3.3-1.7 4.8-5 4.8h-30V248c0 3.3-1.5 4.8-4.8 4.8H605c-3.3 0-4.8-1.5-4.8-4.8V122.3h-30c-3.3 0-5-1.5-5-4.8v-5.6c0-3.3 1.8-4.8 5-4.8h76.3zM729.5 107c5.2 0 8.9 1.6 10.5 7.4l36.8 133.5c.6 2.8-.2 5-4.1 5h-7.2c-3.9 0-5.2-1.5-6.1-5l-10-37.7h-50.7l-10 37.7c-.9 3.5-2 5-6.1 5h-7c-3.9 0-5-2.2-4.2-5l36.8-133.5c1.5-5.8 5.2-7.4 10.5-7.4h10.8zm-27.2 88.4h43.3l-17.8-67.5c-1.1-4.1-1.7-4.8-3.7-4.8-2.2 0-2.6.7-3.9 4.8l-17.9 67.5z" />
  <g>
    <path d="M405.2 275.9c.8 0 1.2.4 1.2 1.1v1.3c0 .8-.4 1.1-1.2 1.1h-7v29.4c0 .8-.4 1.1-1.1 1.1h-1.6c-.8 0-1.1-.4-1.1-1.1v-29.4h-7c-.8 0-1.2-.3-1.2-1.1V277c0-.8.4-1.1 1.2-1.1h17.8zM454.6 296.7l6.8 12c.1.2.2.4.2.6 0 .5-.4.7-1.1.7h-1.9c-.7 0-1.1-.4-1.5-1.1-2.1-3.9-4.3-7.8-6.3-11.7h-5.5v11.7c0 .8-.4 1.1-1.1 1.1h-1.6c-.8 0-1.1-.4-1.1-1.1V277c0-.8.3-1.2 1.1-1.2h8.6c5.5 0 9.3 3.1 9.3 9.7v2c-.1 4.7-2.3 7.9-5.9 9.2zm-9.4-17.3v14.2h5.4c4.2 0 5.8-2.3 5.8-5.9v-2.5c0-4.3-2.1-5.9-6-5.9h-5.2zM508.9 275.9c1.2 0 2.1.4 2.5 1.7l8.6 31.2c.1.7-.1 1.2-1 1.2h-1.7c-.9 0-1.2-.4-1.4-1.2l-2.3-8.8h-11.9l-2.3 8.8c-.2.8-.5 1.2-1.5 1.2h-1.6c-.9 0-1.2-.5-1-1.2l8.6-31.2c.4-1.4 1.2-1.7 2.4-1.7h2.6zm-6.4 20.6h10.1l-4.2-15.8c-.2-1-.4-1.1-.8-1.1-.5 0-.6.2-.9 1.1l-4.2 15.8zM559.1 308.9c0 .8-.4 1.1-1.1 1.1h-1.6c-.7 0-1.1-.4-1.1-1.1V277c0-.8.4-1.2 1.1-1.2h1.6c.8 0 1.1.4 1.1 1.2v31.9zM616.4 310c-.7 0-1.2-.4-1.5-.9l-13.8-25c-.3-.6-.4-.7-.7-.7-.3 0-.4.4-.4 1.4v24.1c0 .8-.4 1.1-1.2 1.1h-1.5c-.8 0-1.1-.4-1.1-1.1v-31.6c0-.9.5-1.4 1.4-1.4h1.9c.7 0 1.2.4 1.5.9l13.8 25c.3.6.5.8.7.8.3 0 .4-.3.4-1.4V277c0-.8.4-1.1 1.2-1.1h1.5c.8 0 1.1.4 1.1 1.1v31.6c0 .8-.5 1.4-1.4 1.4h-1.9zM660.5 308.9c0 .8-.4 1.1-1.1 1.1h-1.6c-.8 0-1.1-.4-1.1-1.1V277c0-.8.4-1.2 1.1-1.2h1.6c.7 0 1.1.4 1.1 1.2v31.9zM717.8 310c-.7 0-1.2-.4-1.5-.9l-13.8-25c-.3-.6-.4-.7-.7-.7-.3 0-.4.4-.4 1.4v24.1c0 .8-.4 1.1-1.2 1.1h-1.5c-.8 0-1.1-.4-1.1-1.1v-31.6c0-.9.5-1.4 1.4-1.4h1.9c.7 0 1.2.4 1.5.9l13.8 25c.3.6.5.8.7.8.3 0 .4-.3.4-1.4V277c0-.8.4-1.1 1.2-1.1h1.5c.8 0 1.1.4 1.1 1.1v31.6c0 .8-.5 1.4-1.4 1.4h-1.9zM778.9 301.4c0 5.7-4.7 9.1-10.3 9.1-7.2 0-11-3.9-11-12.8v-9.5c0-8.9 3.8-12.9 11-12.9 5.8 0 10.2 3.1 10.2 9.2 0 1-.5 1.5-1.4 1.5H776c-.7 0-1.2-.4-1.2-1.2 0-4-2.5-5.9-6.2-5.9-4.7 0-7 2.6-7 8.1v11.8c0 5.6 2.3 8.1 7 8.1 3.9 0 6.3-1.8 6.3-5.9v-4.8h-5c-.8 0-1.2-.4-1.2-1.1v-1.3c0-.7.4-1.1 1.2-1.1h7.8c.9 0 1.2.4 1.2 1.2v7.5z" />
  </g>
  <g>
    <path d="M181.6 236.9c0-6.8-2.9-12.4-8.7-16.7-.7-.5-4.6-2.7-10.2-2.7-1.5 0-3.1.2-4.5.5-7 1.6-13.1 6.8-18.3 15.4-13.1 21.8-33.9 40.3-48.9 50.4l-.2.2 1.9 6.1.4-.3c16-10.4 38-29.8 52.1-53.2 4.3-7.1 9.1-11.3 14.4-12.6 1.1-.3 2.2-.4 3.3-.4 3.6 0 6.2 1.4 6.6 1.7 4.1 3 6.1 6.8 6 11.6-.2 26.1-55.9 70.2-73 83.1l-.2.2 2 6.2.4-.3c18-13.5 76.7-59.5 76.9-89.2z" />
    <path d="M161.5 244.4c0-.1 0-.1.1-.2.3-.5.5-1 .5-1.7 0-1.7-1.4-3-3-3-1.2 0-2.3.7-2.8 1.8-.1.1-.2.2-.2.3-4.1 7.5-20.4 34.1-58.6 62.3l-.2.2 2 6.2.4-.3c39.6-29 56.4-55.5 61.8-65.6z" />
    <path d="M198.9 235c0-19.4-17-35.8-37.1-36h-.1c-16.9 0-30.6 20.5-38.8 32.7l-.2.2c-1.8 2.6-3.3 4.9-4.3 6-9.5 11.1-25 21.6-33.4 26.9l-.2.2 1.9 6 .4-.3c7.2-4.4 25-16.1 36-28.9 1.2-1.5 2.8-3.8 4.8-6.8l.1-.1c7-10.5 20.1-30 33.7-30h.1c16.8.1 31.1 13.8 31.1 29.9 0 10.2-3 36.6-31.1 62.7-14 13-36.9 28.5-53.7 39.2l-.2.2 1.9 6 .4-.3c17.3-11 41-27 55.7-40.6 29.8-27.5 33-56 33-67z" />
    <path d="M214.9 257.2c2.9-17.7 4-40.4-13.7-59.2-9-9.5-23.8-15.2-39.5-15.2h-1.3l-.1.3c-.5 1.6-1.1 3.3-1.6 5l-.3 1.1h.6c1-.1 2-.1 3-.1 6.8 0 23.7 1.3 34.9 13.1 15.8 16.7 14.9 36.6 12.1 54-7.6 45.9-61.9 82.2-93.6 99.8l-1.3.7h2.3c2.1 0 5.7 0 10.3-.1h.1l.1-.1c29.6-17.2 80.4-53.2 88-99.3zM114.5 222.3c8.6-12.4 15.4-19.8 26.2-28 3.4-2.6 9.7-4.5 17-5h.5l-2-6.1h-.3c-7.9.8-14.6 3-18.9 6.3-11.3 8.7-18.5 16.3-27.5 29.4-7 10.1-23.8 23.3-30.4 27.9l-.2.2 1.9 6 .4-.3c6.1-3.9 25.4-19 33.3-30.4z" />
    <path d="M224 198.6c-4.3-7.3-22.9-27.5-57.5-32.5h-.3l-.1.3-1.8 5.1-.2.4.5.1c32.6 4.4 50.4 23.4 54.1 29.7 9.4 16.1 14.2 34.7 14.9 56.8l.1 2.2.7-2.1c1.5-4.5 2.9-9 4.4-13.5v-.2c-2.1-17.5-6.9-32.7-14.8-46.3zM103.5 209.7c.2-.3 18-31 48.5-38.6l.4-.1-1.9-5.8-.3.1c-33.1 8.5-51.7 41-51.9 41.3-3.8 5.9-15.4 21.7-24.3 23.4l-.4.1 1.9 6 .3-.1c12.9-3.4 26.2-24 27.7-26.3z" />
    <path d="M240.5 193.3c-6.1-12.6-30.2-35-68.9-41.5l-.3-.1-.1.3-1.8 5.1-.2.4.5.1c41.4 6.6 61.6 30.5 65.3 38.3 5.6 11.5 7.8 20.8 9.4 29.1l.3 1.5.5-1.4c1.1-3.4 2.2-6.7 3.3-10.1v-.2c-2-7.6-4.5-14.5-8-21.5zM82.8 206.6l1.5-2.4c7.4-11.9 24.7-39.6 63-47.4l.4-.1-1.9-5.9-.3.1c-40.4 8.4-58.6 37.5-66.4 50l-1.5 2.4c-4 6.2-6.1 8-8.3 10-.2.2-.4.3-.6.5l-.4.3 2.1 6.5.4-.5c.8-.8 1.6-1.5 2.4-2.3 2.7-2 5.2-4.2 9.6-11.2z" />
    <path d="M142.6 142.4h.5l-1.9-6h-.3c-36.9 1.4-65.5 40.5-73.4 52.4-1.9 2.9-3.4 5.4-4.7 7.6l-.1.1 2.7 8.6.4-1c2.4-6.3 34.3-61.5 76.8-61.7z" />
    <path d="M86.3 145.7l.3-.4c14-12.2 45.9-14.8 52.1-15.2h.5l-1.9-6h-.3c-6.6.5-23.2 2.1-37.8 7.5 12.3-12.9 22.9-22.2 31.5-27.5l.3-.2-1.9-6-.4.2c-26.1 15.4-62.4 61.5-72.9 75.3l-.1.2 2.3 7.1.4-.6c1.1-1.6 13-17.3 27.9-34.4zM75.6 130.9c18.9-26.3 36.5-41.7 49.6-43.1l.5-.1-1.9-6.1-.3.1c-18.7 3-39 26.5-52.7 45.7-7.1 10-16.4 17.2-22.8 21.5l-.2.2 1.9 6 .4-.3c5.7-3.8 16.9-11.9 25.5-23.9zM66.1 117.4c16.2-24.2 42.9-41 53.7-47.1l.3-.2-.9-2.9-.2-.1s-1.4-.4-3.6-1.2l-.2-.1-.2.2c-7.5 4.4-22 13.5-35.6 26.6C83 82.3 89 68.8 97.1 59.8l.4-.4-.5-.2c-1.7-.7-3.4-1.4-5.2-2.2l-.3-.1-.2.2C77.9 73 70.6 99.4 69.7 102.7c-3.2 3.7-6.1 7.5-8.7 11.3-5.2 7.8-11.4 14.1-18.4 18.6l-.2.2 1.9 6 .4-.3c5.6-3.3 13.8-9.7 21.4-21.1zM79.2 51.6l.1-.3-.3-.2c-1.8-.9-3.5-1.8-4.8-2.5l-.4-.2-.1.5c-2.6 8.8-16.5 53.5-36.7 65.9l-.3.2 1.9 5.9.4-.2C61.1 108 75.4 64.6 79.2 51.6zM60 39.9l-.2-.1c-1.6-1-3.3-2.1-5-3.2l-.5-.3-.1.6c-.9 7.1-5 32.4-23 60.7l-.1.2 2.4 7.5.4-.7c19.2-28.6 24.5-54.5 26-64.3l.1-.4zM40.2 27.1v-.2L37.4 25c-1-.7-2-1.3-3-2l-.7-.5.1.8c2.2 17.9-4.6 41.8-8.9 54.5v.1l3.1 9.6.4-1c3.9-10.5 13-37.4 11.8-59.4zM23.1 15.5L16.4 11l.1.8c.5 5.5 2.2 24.7.9 42.8v.1l4.5 13.9.2-1.6c2.4-15.6 2.3-38.1 1-51.3v-.2zM11.4 33.4c-.9-9.8-1.3-21.8-1.4-26.5v-.2l-.2-.1C7.8 5.2 6 4 4.6 3.1L4.5 3l-.1-.1C2.2 1.5.9.6.9.6L0 0l.3 1.1c0 .1 0 .2.1.3l4.5 14.2L11.7 37l-.3-3.6zM176.4 138.4h-.3l-.1.3c-.6 1.8-1.2 3.5-1.8 5.2l-.2.5h.5c6.2.4 60.9 5.6 80.3 48.8l.4.9 2.6-8.2-.1-.1c-21.5-39.9-71.5-46.5-81.3-47.4z" />
    <path d="M264.5 164.4c-22.7-28.3-69.6-37.6-83.4-39.8h-.3l-2 5.9.4.1c9.7 1.5 43.2 7.9 66.9 25 6.5 4.7 11.8 9.9 15.8 15.6l.4.6 2-6.1.4-1.1-.2-.2zM185.3 112h-.3l-.5 1.6c-.4 1.3-.9 2.6-1.3 3.8l-.1.4.5.1c10.1 1.3 61.7 8.8 85.9 30.2l.4.4.2-.6c.6-1.8 1.2-3.7 1.7-5.5l.1-.2-.2-.2c-26.1-21.4-76.5-28.8-86.4-30z" />
    <path d="M283 105.3c-12.9-3-32-4.5-56.9-4.5-3.9 0-7.7 0-11.3.1l-.2-.1v.1c-11.3.2-20.7.7-26 1h-.3l-.5 1.7c-.4 1.4-.9 2.7-1.3 4l-.2.5h.6c3.9-.2 14.2-.8 27.2-1 4 1.3 37.1 12 60.5 25.4l.4.2.1-.5c.6-1.8 1.1-3.5 1.7-5.2l.1-.3-.3-.2c-14.3-8.1-31.4-15-43.9-19.6 21.1.2 37.5 1.6 48.7 4.2l.3.1.3-.9c.5-1.7 1-3.2 1.4-4.5l.1-.4-.5-.1zM157.7 355.7l-1.1.7h1.3c3.2 0 6.4-.1 9.5-.1h.1l.1-.1c19.7-14 37-31.2 51.5-51l.1-.1c1.1-3.4 2.2-6.8 3.4-10.4l3.6-11.2-1.7 2.8c-11.8 19.4-33 47.2-66.8 69.4zM189.2 355.4l-.9.7 4.6-.1c2 0 3.8 0 5.2-.1h.1l.1-.1c2-1.7 3.9-3.4 6-5.4l.1-.1 1.3-3.9c.6-2 1.4-4.3 2.2-6.8l.5-1.5-1.1 1.1c-5.5 5.7-11.6 11.1-18.1 16.2z" />
  </g>
</svg>
  )
}