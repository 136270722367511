import React from "react"

export default function Vprint() {
  return (
<svg xmlns="http://www.w3.org/2000/svg" className="w-full h-auto fill-current" id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 284.1 357.7" xmlSpace="preserve">
  <defs/>
  <path d="M182.1 237.5c0-6.8-2.9-12.5-8.7-16.8-.7-.5-4.6-2.7-10.2-2.7-1.5 0-3.1.2-4.6.5-7 1.6-13.2 6.8-18.4 15.4-13.1 21.9-34 40.4-49.1 50.5l-.2.2 2 6.1.4-.3c16-10.4 38.1-29.9 52.2-53.3 4.3-7.1 9.1-11.4 14.4-12.6 1.1-.3 2.2-.4 3.3-.4 3.6 0 6.2 1.4 6.7 1.7 4.1 3 6.1 6.8 6.1 11.7-.2 26.2-56 70.4-73.2 83.3l-.2.2 2 6.2.4-.3c18-13.5 76.9-59.6 77.1-89.4" />
  <path d="M161.9 245c0-.1 0-.1.1-.2.3-.5.5-1.1.5-1.7 0-1.7-1.4-3-3-3-1.2 0-2.3.7-2.8 1.8-.1.1-.2.2-.2.3-4.1 7.5-20.5 34.2-58.8 62.5l-.2.2 2 6.2.4-.3c39.8-29.1 56.6-55.7 62-65.8" />
  <path d="M199.4 235.6c0-19.4-17.1-35.9-37.2-36.1h-.2c-16.9 0-30.7 20.5-38.9 32.8l-.2.2c-1.8 2.6-3.3 4.9-4.3 6-9.5 11.1-25.1 21.7-33.5 27l-.2.2 1.9 6 .4-.3c7.2-4.4 25.1-16.2 36.1-28.9 1.2-1.5 2.8-3.8 4.8-6.8l.1-.1c7-10.5 20.1-30.1 33.8-30.1h.1c16.9.1 31.2 13.8 31.2 30 0 10.2-3 36.7-31.2 62.9-14 13-37 28.5-53.8 39.3l-.3.2 1.9 6.1.4-.3c17.4-11 41.1-27 55.9-40.7 30-27.9 33.2-56.4 33.2-67.4" />
  <path d="M215.5 257.9c2.9-17.7 4-40.5-13.7-59.3-9-9.5-23.8-15.2-39.6-15.2h-1.3l-.1.3c-.5 1.6-1.1 3.3-1.6 5l-.3 1.1h.6c1 0 2-.1 3-.1 6.8 0 23.8 1.3 35 13.2 15.8 16.7 15 36.7 12.1 54.1-7.6 46-62 82.4-93.8 100l-1.3.7h2.3c2.1 0 5.7 0 10.3-.1h.2c29.6-17.5 80.5-53.6 88.2-99.7M114.8 222.9c8.6-12.5 15.5-19.8 26.2-28.1 3.4-2.6 9.8-4.5 17-5h.5l-2-6.2h-.3c-7.9.8-14.7 3-19 6.3-11.3 8.7-18.5 16.4-27.5 29.4-7 10.1-23.8 23.4-30.5 27.9l-.2.2 1.9 6 .4-.3c6.2-3.7 25.6-18.8 33.5-30.2" />
  <path d="M224.5 199.1c-4.3-7.4-23-27.6-57.6-32.6h-.3l-.1.3-1.8 5.1-.2.4.5.1c32.7 4.4 50.5 23.5 54.2 29.8 9.4 16.2 14.3 34.8 14.9 56.9l.1 2.2.7-2.1c1.5-4.5 2.9-9 4.4-13.5v-.2c-2-17.6-6.8-32.7-14.8-46.4M103.7 210.2c.2-.3 18-31 48.6-38.7l.4-.1-1.9-5.8-.3.1c-33.2 8.5-51.8 41.1-52 41.4-3.8 5.9-15.5 21.8-24.4 23.5l-.4.1 1.9 6 .3-.1c13-3.4 26.3-24 27.8-26.4" />
  <path d="M241.2 193.8c-6.1-12.6-30.2-35.1-69.1-41.6l-.3-.1-.1.3-1.8 5.1-.2.4.5.1c41.5 6.6 61.7 30.6 65.5 38.4 5.6 11.6 7.8 20.9 9.4 29.2l.3 1.5.5-1.4c1.1-3.4 2.2-6.7 3.3-10.1v-.2c-2.1-7.7-4.6-14.5-8-21.6M83 207.2l1.5-2.4c7.4-11.9 24.8-39.8 63.1-47.5l.4-.1-1.9-5.9-.3.1c-40.5 8.4-58.8 37.6-66.5 50.1l-1.5 2.4c-4 6.2-6.1 8.1-8.3 10-.2.2-.4.3-.6.5l-.4.3 2.1 6.5.4-.5c.8-.8 1.6-1.5 2.5-2.3 2.5-2.1 5.1-4.3 9.5-11.2" />
  <path d="M143 142.7h.5l-1.9-6h-.3c-37 1.4-65.7 40.6-73.6 52.5-1.9 2.9-3.4 5.4-4.7 7.6l-.1.1 2.7 8.6.4-1c2.3-6.2 34.3-61.5 77-61.8" />
  <path d="M86.5 146.1l.3-.4c14.1-12.3 46-14.9 52.2-15.3h.5l-1.9-6h-.3c-6.6.5-23.3 2.1-37.9 7.5 12.3-13 23-22.3 31.5-27.6l.3-.2-1.9-6-.4.2c-26.1 15.4-62.6 61.7-73.1 75.5l-.1.2 2.3 7.1.4-.6c1.3-1.5 13.1-17.3 28.1-34.4M75.8 131.2c18.9-26.4 36.6-41.8 49.7-43.2h.5l-1.9-6.1h-.3c-18.7 3-39.1 26.5-52.9 45.8-7.1 10-16.4 17.2-22.9 21.5l-.2.2 1.9 6.1.4-.3c5.9-3.9 17.1-12 25.7-24M66.3 117.7c16.2-24.2 43-41.1 53.8-47.3l.3-.2-.9-3-.2-.1s-1.4-.4-3.7-1.2l-.2-.1-.2.1c-7.5 4.4-22 13.6-35.7 26.7C83.3 82.5 89.2 69 97.4 60l.4-.4-.5-.2c-1.7-.7-3.4-1.4-5.2-2.2l-.3-.1-.2.2C78.1 73.2 70.7 99.7 69.8 103c-3.2 3.7-6.1 7.5-8.7 11.3-5.3 7.9-11.5 14.1-18.4 18.6l-.2.2 1.9 6 .4-.3c5.6-3.3 13.9-9.7 21.5-21.1M79.4 51.7l.1-.3-.3-.1c-1.8-.9-3.5-1.8-4.8-2.6l-.4-.2-.2.5c-2.6 8.8-16.5 53.6-36.8 66.1l-.3.2 1.9 5.9.4-.2c22.3-12.7 36.6-56.2 40.4-69.3M60.1 40l-.2-.1c-1.6-1-3.3-2.1-5-3.3l-.5-.3-.1.6c-.8 7.1-5.1 32.5-23 60.9l-.1.2 2.4 7.5.4-.7c19.2-28.7 24.6-54.7 26-64.5l.1-.3zM40.3 27.1v-.2L37.4 25c-1-.7-2-1.3-3-2l-.7-.5.1.8c2.2 18-4.6 41.9-8.9 54.6v.1l3.1 9.6.4-1c4-10.4 13.1-37.4 11.9-59.5M23.2 15.5L16.5 11l.1.8c.5 5.5 2.2 24.8.9 42.9v.1l4.5 14 .2-1.6c2.4-15.6 2.3-38.2 1-51.5v-.2zM11.4 33.5c-.9-9.8-1.3-21.9-1.4-26.6v-.2l-.2-.1C7.8 5.2 6 4.1 4.6 3.1L4.5 3h-.1C2.2 1.5.8.6.8.6L0 0l.3 1.1c0 .1 0 .2.1.3l4.5 14.2L11.8 37l-.4-3.5zM176.8 138.8h-.3l-.1.3c-.6 1.8-1.2 3.5-1.8 5.2l-.2.5h.5c6.2.4 61 5.6 80.5 48.9l.4.9 2.6-8.2-.1-.1c-21.5-40-71.7-46.6-81.5-47.5" />
  <path d="M265.2 164.9c-22.8-28.4-69.8-37.7-83.6-39.9h-.3l-2 5.9.4.1c9.7 1.5 43.3 7.9 67.1 25.1 6.5 4.7 11.8 10 15.8 15.6l.4.6 2-6.1.4-1.1-.2-.2zM185.8 112.2h-.3l-.5 1.6c-.4 1.3-.9 2.6-1.3 3.9l-.1.4.5.1c10.1 1.3 61.9 8.8 86.1 30.3l.4.4.2-.6c.6-1.9 1.2-3.7 1.8-5.5l.1-.2-.2-.2c-26.3-21.5-76.8-28.9-86.7-30.2" />
  <path d="M283.7 105.6c-12.9-3-32.1-4.5-57-4.5-3.9 0-7.7 0-11.4.1l-.2-.1v.1c-11.3.2-20.7.7-26.1 1h-.3l-.5 1.7c-.4 1.4-.9 2.7-1.3 4l-.2.5h.6c3.9-.2 14.3-.8 27.3-1 4 1.3 37.2 12 60.6 25.5l.4.2.1-.5c.6-1.8 1.1-3.5 1.7-5.2l.1-.3-.3-.2c-14.4-8.2-31.5-15.1-44-19.7 21.1.2 37.6 1.7 48.8 4.2l.3.1.3-.9c.5-1.7 1-3.2 1.4-4.6l.1-.4h-.4zM158.1 356.6l-1.1.7h1.3c3.2 0 6.4-.1 9.5-.1h.1l.1-.1c19.7-14.1 37.1-31.3 51.6-51.2l.1-.1c1.1-3.4 2.2-6.8 3.4-10.4l3.6-11.2-1.7 2.8c-11.7 19.5-33 47.3-66.9 69.6M189.7 356.3l-.9.7 4.6-.1c2 0 3.8 0 5.2-.1h.1l.1-.1c2-1.7 3.9-3.4 6.1-5.4l.1-.1 1.3-3.9c.6-2 1.4-4.3 2.2-6.8l.5-1.5-1.1 1.1c-5.6 5.7-11.8 11.1-18.2 16.2" />
</svg>
  )
}
